<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />
    <div class="apipe-cloud-course-container-madeSolution">
      <div class="pc-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h1 style="margin-bottom: 15px; font-size: 42px; font-weight: 700">
            优制云企业资源计划ERP
          </h1>
          <p class="tit">
            优制云工业互联网平台ERP，集资源管理、生产计划、成本控制、财务管理等功能于一体，助力<br />企业实现精细化管理。智能优化资源配置，提升生产效率；精准成本分析，助您把握经营脉搏。<br />财务管理清晰透明，风险预警及时。优制云ERP，让您的企业资源利用更高效，决策更明智，为<br />中小微企业打造强大后台支撑。
          </p>
        </div>
      </div>

      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="text-align: left">
          <div class="title">优制云企业资源计划ERP</div>
          <p class="tit">
            优制云工业互联网平台ERP，集资源管理、生产计划、成本控制、财务管理等功能于一体，助力企业实现精细化管理。智能优化资源配置，提升生产效率；精准成本分析，助您把握经营脉搏。财务管理清晰透明，风险预警及时。优制云ERP，让您的企业资源利用更高效，决策更明智，为中小微企业打造强大后台支撑。
          </p>
        </div>
      </div>

      <!-- 行业痛点 -->
      <div class="pc-industry d-none d-lg-block">
        <div class="pro-tit">行业痛点</div>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/erp/hangye1.png" alt="" />
                <h6>资源优化配置难</h6>
                <div class="des">
                  <div>资源分配不合理，利用率低</div>
                  <!-- <div>或库存不足</div> -->
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/erp/hangye2.png" alt="" />
                <h6>决策支持不足</h6>
                <div class="des">
                  <div>缺乏实时的数据，决策困难</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/erp/hangye3.png" alt="" />
                <h6>数据孤岛现象</h6>
                <div class="des">
                  <div>各部门数据独立，信息共享困难</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/erp/hangye4.png" alt="" />
                <h6>业务流程不协同</h6>
                <div class="des">
                  <div>流程衔接不畅，影响工作效率</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>
      <div class="mobile-industry d-block d-lg-none">
        <div class="pro-tit">行业痛点</div>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/hangye1.png" alt="" />
                <h6>供应商管理困难</h6>
                <div class="des">
                  <div>供应商评估和选择缺乏数据支持</div>
                  <!-- <div>或库存不足</div> -->
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/hangye2.png" alt="" />
                <h6>供应链透明度低</h6>
                <div class="des">
                  <div>供应链环节信息不透明，响应</div>
                  <div>速度慢</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/hangye3.png" alt="" />
                <h6>库存成本高</h6>
                <div class="des">
                  <div>库存控制不当，导致库存积压</div>
                  <div>或短缺</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/hangye4.png" alt="" />
                <h6>物流成本高</h6>
                <div class="des">
                  <div>物流规划不合理，运输成本高</div>
                  <!-- <div>杂，容易出错</div> -->
                </div>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <!-- 解决方案 -->
      <div class="container text-center">
        <div class="pro-tit d-none d-lg-block" style="font-size: 36px">
          解决方案
        </div>
        <div
          class="pro-tit d-block d-lg-none"
          style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
        >
          解决方案
        </div>
        <img
          style="width: 100%"
          src="@/assets/newSite/erp/solution.png"
          alt=""
        />
      </div>

      <!-- 产品能力 -->
      <div class="pc-pro-power d-none d-lg-block">
        <div class="pro-tit" style="font-size: 36px">产品能力</div>
        <div>
          <div class="tabs container text-center">
            <div
              :class="['items', tbx == index ? 'active' : '']"
              v-for="(item, index) in tabListNew"
              :key="index"
              @click="handleTab(index)"
            >
              <img :src="item.img1" />
              <div class="tit">{{ item.tit }}</div>
            </div>
          </div>
          <div class="tab-cot">
            <div class="container text-center">
              <div class="row" v-show="tbx == 0">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">销售订单，发货通知单，销售对账</div>
                  <div class="mcot">
                    <div>
                      对销售管理全过程进行追溯，并销售带动整个供应链下游生态圈活跃
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/isc/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 1">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">
                    采购单价，采购申请，采购订单，采购对账单，采购付款申请单
                  </div>
                  <div class="mcot">
                    <div>
                      企业为了达成生产及销售计划，选择合适的供货单位，确保的质量，数量，时间，及适合价格情况进行经营计划活动工作
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/isc/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 2">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">生产工单，生产投料单，生产补料单</div>
                  <div class="mcot">
                    <div>
                      生产制造过程追溯，物料去向等调度指挥依据，并与CRM，ISC，MES，WMS系统等集成
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/isc/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 3">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">
                    采购入库，产品入库，其他入库，销售出库，生产领料，其他出库，调拨单，盘点单，即时库存
                  </div>
                  <div class="mcot">
                    <div>
                      企业经营管理过程所有物件出入库行为进行管控，以见单备货；利用运营管控手段管控账目与实物数量一致性；减少企业财产损失
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/isc/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 4">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">
                    出入库明细报表，销售日常报表，采购日常报表，生产日常报表
                  </div>
                  <div class="mcot">
                    <div>
                      提供数据分析，统计，挖掘等工具平台，让客户自行根据所需报表配置化发布等
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/isc/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-pro-power d-block d-lg-none">
        <h4 class="pro-tit">产品能力</h4>
        <div class="">
          <div class="tabs">
            <div
              :class="['items', idx == index ? 'active' : '']"
              v-for="(item, index) in tabListNew"
              :key="index"
              @click="handleTab(index)"
            >
              <img :src="item.img1" />
              <div class="tit">{{ item.tit }}</div>
            </div>
          </div>
          <div class="tab-cot">
            <div class="container text-center">
              <div class="row" v-show="idx == 0">
                <div class="col-xs-12 col-md-6 lf">
                  <h4 style="text-align: left">生产BOM、文件管理</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      color: #333333;
                    "
                  >
                    <div>工单生产BOM和对应资料的管理</div>
                    <div>便于管理工单资料核对生产BOM信息</div>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/mes/a.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
                <div style="text-align: left; margin-top: 10px">
                  <el-button @click="handleTest" type="primary" round>
                    免费试用
                  </el-button>
                </div>
              </div>

              <div class="row" v-show="idx == 1">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p>
                    无纸化作业，降低企业成本 使用移动IPDA进行检验登记，方便快捷
                    网络传输，减少中间环节 加快现场响应时间，减少等待浪费
                  </p>
                  <div>
                    <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                          免费试用
                        </button> -->
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/madeSolution/p2.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="idx == 2">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p>
                    通过出货单、箱号、客户序列号，从后往前查询该产品所经工序、人、机、料、法、测等信息；
                    无纸化作业，降低企业成本 一键追溯，方便快捷
                    追溯报告网络传输，减少中间环节
                    对追溯不良品，现场及时响应，减少等待浪费
                    根据客户情况，可实现批次级或物料级追溯，方便问题定位
                  </p>
                  <div>
                    <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                          免费试用
                        </button> -->
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/madeSolution/p3.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="idx == 3">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p>
                    自动记录生产过程中的各项数据，节省人工
                    快速统计分析，并以图表形式展示，标准客观，堵截人工统计出错
                    数据展示实时，方便领导决策
                  </p>
                  <div>
                    <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                          免费试用
                        </button> -->
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/madeSolution/p4.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 产品优势 -->
      <div class="pc-scene d-none d-lg-block">
        <div class="pro-tit d-none d-lg-block">产品优势</div>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/erp/ad1.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/erp/ad2.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/erp/ad3.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/erp/ad4.png" alt="" />
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>
      <!-- 产品优势 -->
      <div class="mobile-scene d-block d-lg-none">
        <div
          class="pro-tit d-block d-lg-none"
          style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
        >
          产品优势
        </div>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/erp/ad1.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/erp/ad2.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/erp/ad3.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/erp/ad4.png" alt="" />
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <!-- 成功案列 -->
      <section
        class="pc-product d-none d-lg-block"
        style="margin: 80px 0 50px 0"
      >
        <div class="container text-center">
          <h1 class="pro-tit" style="margin-bottom: 20px; font-size: 36px">
            成功案例
          </h1>
          <div class="row align-items-start">
            <div
              :class="['col', idx == index ? 'acticv' : '']"
              @click="handleSwitch(index)"
              v-for="(item, index) in tabs"
              :key="index"
            >
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
        <div class="pro-wrap">
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 0"
          >
            <div class="content" style="display: flex;padding:20px 0 ;">
              <div class="wrap">
                <h6 style="font-size: 35px">
                  {{ caseA.contentTitle }}
                </h6>
                <p class="p-content">
                  {{ caseA.contentDescription }}
                </p>
                <div>
                  <el-button
                    type="primary"
                    size="mini"
                    round
                    @click="
                      () => {
                        this.openNewWindow(`/caseDetail?id=${caseA.id}&tit=${caseA.contentTitle}`);
                      }
                    "
                    class="btn btn-primary btn-sm"
                  >
                    查看详情
                  </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img
                  :src="baseImgUrl + caseA.contentImgUrl"
                  width="640px"
                  height="360px"
                />
              </div>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 1"
          >
            <div class="content" style="display: flex;padding:20px 0 ;">
              <div class="wrap" style="flex: 60%">
                <h6 style="font-size: 35px">{{ caseB.contentTitle }}</h6>
                <p class="p-content">
                  {{ caseB.contentDescription }}
                </p>
                <div>
                  <el-button
                    type="primary"
                    size="mini"
                    round
                    @click="
                      () => {
                        this.openNewWindow(`/caseDetail?id=${caseB.id}&tit=${caseB.contentTitle}`);
                      }
                    "
                    class="btn btn-primary btn-sm"
                  >
                    查看详情
                  </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img
                  :src="baseImgUrl + caseB.contentImgUrl"
                  width="640px"
                  height="360px"
                />
              </div>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInRightBig"
            v-show="idx == 2"
          >
            <div class="content" style="display: flex;padding:20px 0 ;">
              <div class="wrap" style="flex: 60%">
                <h6 style="font-size: 35px">{{ caseC.contentTitle }}</h6>
                <p class="p-content">
                  {{ caseC.contentDescription }}
                </p>
                <div>
                  <el-button
                    type="primary"
                    size="mini"
                    round
                    @click="
                      () => {
                        this.openNewWindow(`/caseDetail?id=${caseC.id}&tit=${caseC.contentTitle}`);
                      }
                    "
                    class="btn btn-primary btn-sm"
                  >
                    查看详情
                  </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img
                  :src="baseImgUrl + caseC.contentImgUrl"
                  width="640px"
                  height="360px"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="mobile-product d-block d-lg-none"
        style="margin-top: 40px"
      >
        <div class="container text-center">
          <h3 class="pro-tit">成功案例</h3>
          <div class="row align-items-start">
            <div
              :class="['col', idx == index ? 'acticv' : '']"
              @click="handleSwitch(index)"
              v-for="(item, index) in tabs"
              :key="index"
            >
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
        <div class="pro-wrap">
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 0"
          >
            <div class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: 700; margin-bottom: 1rem">
                  优制云助力华瀚管道实现智能化生产
                </h6>
                <p class="p-content">
                  优制云助力华瀚管道实现智能化生产，通过智能制造MES系统的深度应用，实现了生产流程的精细化管理和智能化控制。系统优化了生产计划、实时监控设备状态、自动采集生产数据，有效提升了生产透明度，为华瀚管道带来了显著的经济效益和市场竞争优势。
                </p>
              </div>
              <div style="text-align: center; margin-bottom: 20px">
                <img src="@/assets/newSite/home/cases.png" />
              </div>
              <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow('/smartMade');
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  了解更多
                </el-button>
              </div>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 1"
          >
            <div class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: bold; margin-bottom: 0.5rem">
                  智能办公
                </h6>
                <p class="p-content">
                  智能化：借助先进的技术和设备，智能办公能够实现智能化决策、智能化处理、智能化控制等，提高工作效率和质量。
                  高效性：能够减少繁琐的手动操作，简化工作流程，提高工作效率。
                  协同性：能够实现团队协作的高效协同，提高团队协作效率。
                  安全性：具备较高的安全性，能够保护企业和员工的隐私和数据安全。
                  移动性：支持移动办公，IM即时聊天，员工可以随时随地进行办公，提高工作效率和灵活性。
                  云端化：基于云计算技术实现，能够实现数据的云端存储、备份和恢复等功能，提高数据的安全性和可靠性。
                  数据分析：能够对各种数据进行分析和挖掘，为企业提供更全面的数据支持和决策参考。
                </p>
                <div class="btns" style="">
                  <el-tag type="info" style="">伟班（OA）</el-tag>
                </div>
                <div>
                  <button
                    @click="
                      () => {
                        this.openNewWindow('/smartOffice');
                      }
                    "
                    type="button"
                    class="btn btn-primary btn-sm"
                  >
                    了解更多
                  </button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img src="@/assets/newSite/home/s2.png" />
              </div>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInRightBig"
            v-show="idx == 2"
          >
            <div class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: bold; margin-bottom: 0.5rem">
                  智慧园区
                </h6>
                <p class="p-content">
                  数据可视化及应用：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行…
                  能耗管理：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行，访客通行权限…
                  安全管理：视频上云助力园区远程巡视，降本增效；制定园区安全检查标准，通过视频巡更+图片巡更方式进行园区日常巡逻，提高管理效率…
                  设备运维：融合AIoT技术，实现对设备资产的全生命周期管理，变配电房、给排水房等机电设备智能化实时监测，降低安全隐患…
                  服务体验：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行…
                  集成能力：打通园区运营系统、工单等系统，实现智能化数据与业务系统的流程自动化、全场景闭环，可将智能化应用集成至现有员工app…
                </p>
                <div class="btns">
                  <el-tag type="info" style="">智慧园区</el-tag>
                </div>
                <div>
                  <button
                    @click="
                      () => {
                        this.openNewWindow('/smartPark');
                      }
                    "
                    type="button"
                    class="btn btn-primary btn-sm"
                  >
                    免费试用
                  </button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img src="@/assets/newSite/home/s3.png" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <right-nav-new class="d-none d-lg-block" />
    </div>

    <NewFooter />
    <MobileFooter class="d-block d-lg-none" />
  </div>
</template>
            
    <script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperCore, { Autoplay } from "swiper";
SwiperCore.use([Autoplay]);
import Qs from "qs";
import { Newslist } from "@/api/fetch";
export default {
  name: "hardware",
  data() {
    return {
      tabs: ["金溆科技", "锦瀚城乡建设", "润玉空间"],
      activeName: "1",
      idx: 0,
      tbx: 0,
      ids: -1,
      tabListNew: [
        {
          img1: require("@/assets/newSite/isc/p1.png"),
          tit: "销售管理",
        },
        {
          img1: require("@/assets/newSite/isc/p2.png"),
          tit: "采购管理",
        },
        {
          img1: require("@/assets/newSite/isc/p3.png"),
          tit: "生产管理",
        },
        {
          img1: require("@/assets/newSite/isc/p4.png"),
          tit: "进销存",
        },
        {
          img1: require("@/assets/newSite/isc/p5.png"),
          tit: "报表分析",
        },
      ],

      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      baseImgUrl: "https://admin.veiban.com",
      caseList: [],
      caseA: {},
      caseB: {},
      caseC: {},
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  created() {
    this.getCase();
  },

  methods: {
    //成功案例
    getCase() {
      Newslist(
        Qs.stringify({
          categoryId: "1839482945283117058",
          pageSize: 10,
          pageNo: 1,
          productCode: "erp",
        })
      ).then((res) => {
        if (res.data.rows.length) {
          this.caseA = res.data.rows[0];
          this.caseB = res.data.rows[1];
          this.caseC = res.data.rows[2];
          this.caseList = res.data.rows;
        }
      });
    },
    openNewWindow(path) {
      const routeData = this.$router.resolve({ path, id: "123456" });
      window.open(routeData.href, "_blank");
    },
    handleSwitch(idx) {
      this.idx = idx;
    },
    handleTest() {
      window.open("https://apipecloud.veiban.com/login");
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },

    handleTab(index) {
      this.tbx = index;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll, false);
  },
};
</script>
            
    <style lang="less" scoped>
.apipe-cloud-course-container-madeSolution {
  .pc-banner {
    height: 600px;
    background-image: url("../../assets/newSite/erp/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 18px;
        margin: 4px 0;
      }
      .exp {
        margin-top: 2rem;
        font-size: 14px;
        border: 1px solid white;
        display: inline-block;
        width: 100px;
        padding: 2px 0;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .mobile-banner {
    height: 300px;
    width: 100%;
    background-image: url("../../assets/newSite/erp/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .container {
      color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .title {
        font-size: 32px;
        text-align: left;
        color: white;
        font-family: Source Han Sans CN;
        font-weight: 200;
        margin-left: 0;
      }
      .tit {
        font-size: 14px;
        margin-top: 15px;
      }
      .exp {
        margin-top: 0.5rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }
  .pc-industry {
    margin: 80px 0;
    .pro-tit {
      margin-bottom: 40px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        background: #f6faff;
        height: 240px;
        width: 300px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }
    img {
      width: 100px;
      height: 100px;
    }
    h6 {
      margin-top: 0.5rem;
      font-size: 16px;
      font-weight: 700;
      color: rgba(51, 51, 51, 1);
    }
    .des {
      color: #333333;
      font-size: 14px;
    }
  }
  .mobile-industry {
    margin: 40px 0;
    .pro-tit {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 700;
      color: #333;
    }
    .col {
      .p-3 {
        background: #f6faff;
        height: 240px;
        margin: 20px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }
    img {
      width: 100px;
      height: 100px;
    }
    h6 {
      margin-top: 0.5rem;
      font-size: 16px;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
    }
    .des {
      color: #333333;
      font-size: 14px;
    }
  }
  .pc-pro-power {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
    }
    .tabs {
      display: flex;
      .items {
        flex: 25%;
        // height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        .tit {
          padding: 20px 0 16px 0;
          font-size: 18px;
          font-weight: 700;
        }
        &:hover {
          cursor: pointer;
        }
        img {
          width: 36px;
          height: 36px;
        }
      }
      .active {
        .tit {
          border-bottom: 4px solid rgba(20, 132, 255, 1);
        }
      }
    }
    .tab-cot {
      background: #f6faff;
      padding: 20px;
      .row {
        min-height: 300px;
        padding: 20px 100px;
        .mtit {
          text-align: left;
          font-size: 24px;
          font-weight: 500;
        }
        .mcot {
          text-align: left;
          margin: 20px 0 30px 0;
          color: #333333;
        }
        .lf {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          justify-content: center;
          padding: 10px;
          .btn {
            width: 120px;
            background: #1484ff;
            &:hover {
              background: #66b1ff;
            }
          }
        }
        p {
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }
  .mobile-pro-power {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 20px;
      color: #333;
      font-weight: 700;
    }
    .tabs {
      display: flex;
      width: 375px;
      overflow-x: scroll;
      overflow-y: hidden;
      margin: 0 auto;
      .items {
        width: 33%;
        flex-shrink: 0;
        height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        &:hover {
          cursor: pointer;
        }
        .tit {
          padding: 20px 0 16px 0;
        }
        img {
          width: 60px;
          height: 60px;
        }
      }
      .active {
        .tit {
          border-bottom: 4px solid rgba(20, 132, 255, 1);
        }
      }
    }
    .tab-cot {
      background: #f6faff;
      padding: 20px;
      .row {
        min-height: 300px;
        padding: 10px;
        .lf {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          justify-content: center;
          padding: 10px;
          .btn {
            width: 100px;
          }
        }
        p {
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }
  .pc-scene {
    margin: 80px 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        min-height: 200px;
        padding: 0 !important;
      }
    }
    img {
      width: 100%;
    }
    h6 {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
    }
  }
  .mobile-scene {
    margin: 0 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        min-height: 200px;
        padding: 5px !important;
      }
    }
    img {
      width: 100%;
      height: 320px;
    }
    h6 {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
    }
  }

  .pc-product {
    .acticv {
      span {
        height: 4px;
        border-bottom: 4px solid #1484ff;
        color: #1484ff;
      }
    }
    .col {
      cursor: pointer;
      span {
        display: inline-block;
        height: 40px;
      }
    }
    .pro-wrap {
      background: #f6faff;
      text-align: left;
      .container {
        display: flex;

        .cot {
          background: url("../../assets/newSite/home/pc.png");
          background-repeat: no-repeat;
          background-size: cover;
          height: 702px;
          width: 100%;
        }
        img {
          // height: 16rem;
        }
        .content {
          margin: 40px 0;
          width: 100%;
          .wrap {
            flex: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
              font-family: Source Han Sans CN;
              font-weight: 200;
              color: #333333;
              width: 540px;
              margin-bottom: 20px;
              margin-top: 12px;
              font-size: 18px;
            }
          }
          .btn {
            width: 120px;
            background: #1484ff;
            height: 36px;
          }
        }
      }
    }
  }
  .mobile-product {
    .acticv {
      span {
        height: 4px;
        border-bottom: 4px solid #1484ff;
        color: #1484ff;
      }
    }
    .col {
      padding: 1rem 0;
      cursor: pointer;
      span {
        display: inline-block;
        height: 40px;
      }
    }
    .pro-tit {
      font-family: Source Han Sans CN;
      font-weight: 700;
      color: #333333;
      font-size: 20px;
    }
    .pro-wrap {
      text-align: left;
      .container {
        display: flex;
        .content {
          padding: 1rem 1.5rem 1.5rem 1.5rem;
          background: white;
          width: 100%;
          img {
            width: 100%;
            // height: 20rem;
          }
          .p-content {
            color: #666;
            font-size: 0.8rem;
            font-family: "思源黑体";
            line-height: 1.6rem;
          }
          .btn {
            width: 120px;
            background: #1484ff;
            height: 36px;
            color: white;
          }
        }
      }
    }
  }
}
</style>
            